.login_site_ctnr{

	.wrapper{max-width: 750px;}
	.login_ctnr{padding-top: 20vh; 
		.logo{
			width: 290px;
		}
		.ctnt{
			text-align: left; padding-top: 0; margin-top: 0; border-top: 0; padding-left: 20px; border-left: 1px solid $c_gray_e; 
		}
	}
}