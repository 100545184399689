.h1{font-size: 36px;}

.main_actions{font-size: 0; line-height: 0;
	@include trash;
	.multi_action{width: 300px; }
	.btn{width: auto; }
	>li{float: right;  margin-left: 10px;
		&.left{float: left; margin-left: 0; margin-right: 10px; }
	}
}

.options{margin-top: -65px; }

.two_cols,.three_cols{
	@include trash; @include display-flex;@include flex-flow(row wrap);margin: 0 -10px; 
	.col{width: 50%; float: left; @include display-flex;@include flex-flow(row wrap); padding: 0 10px;
	 @include box-sizing(border-box); @include align-content(flex-start);
		>*{width: 100%; @include box-sizing(border-box);}
	}
}
.three_cols{
	.col{width: 33.3333%;}
}

.two_cols.consult_form_two_cols{
	.col{padding-right: 30px; 
		+.col{padding-right: 10px; padding-left: 30px; border-left: 1px solid $c_gray_e; }
	}
}

.status{min-width: 100px; }
@media only screen and (min-width : 1024px) {
  

	

}
