.form{
  .form_entry{

    @include trash;
    .field{ width: 50%; float: left; padding-right: 20px; 
      +.field{margin-top: 0; padding-right: 0px; padding-left: 20px;}
    }

    &.wide,&.small{
      .field{padding: 0;width: 100%; float: none;}
    }

  }




  .deepselector-container{
    @include trash;
    .deepselector-subcontainer{
      width: 30%; margin-right: 5%; float: left; @include box-sizing(border-box);
      &:nth-child(3n+3){margin-right: 0; }
    }
  }

  .collection{
    #appbundle_consultation_interventions{
      .multiplefield-form{padding-left: 0;}

      .part_subtitle {
        font-size: 18px;
      }

      .field {
        float: none;
        margin-top: 15px;
        padding-right: 0;
        width: 100%;
      }

      .field + .field {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }


  .form_footer{
    clear: both;@include trash;
    .mention{ float: left; }
    .buttons{float: right; 
      .btn{width: auto; margin-left: 20px; }
    }
  }

}

@media only screen and (min-width: 1024px) {
  .form{
    .collection{
      #appbundle_consultation_interventions{
        .field {
          float: left;
          width: 50%;

          &:nth-child(odd) {
            padding-right: 20px;
            clear: both;
          }

          &:nth-child(even) {
            padding-left: 20px;
          }

          &:nth-child(4) {
            clear: both;
            padding-left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}