.share_blk{
	text-align: center;
	.buttons{margin: 0 -1%; 
		.btn{ width: 48%; margin: 0 1% 20px; }
	}
}

.two_cols.two_cols_consult{
	margin: 0 -30px;
	.col{padding: 0 30px;border-left: 1px solid $c_gray_f; 
		&:first-child{border-left: none; }
	}
}

.plan_chooser_ctnr{
	.customselect-container{display: none; }
	.plan_chooser{
		display: block; @include display-flex;@include flex-flow(row wrap);margin: 0 -10px; 
		li{width: 25%; padding: 5px 10px; @include box-sizing(border-box);@include display-flex;@include flex-flow(row nowrap);
			span{
				display: block; padding: 50px 15px 20px; font-weight: 600; text-align: center; border: 1px solid $c_gray_f; @include basic_trs;
				width: 100%; @include box-sizing(border-box);@include box-shadow(0 5px 5px rgba(220, 220, 220, 0.15)); position: relative; cursor: pointer; 
				@include display-flex;@include flex-flow(column nowrap);@include justify-content(flex-start);
				&:before,&:after{position: absolute; width: 10px; height: 10px;top: 15px; left: 50%; @include border-radius(100%); margin-left: -6px; }
		        &:before{content:''; border: 1px solid $c_main; 
		          @include transition(border-color .2s);
		        }
		        &:after{content:''; width: 6px; height: 6px; margin-top: 3px;margin-left: -3px; background-color: $c_blue;opacity: 0; 
		          @include transition(opacity .2s);
		        }
				&:hover{color: $c_blue; 
					&:before{border-color: $c_blue; }
				}

			}
			&.active{
				span{
					background-color: $c_gray_f; color: $c_blue; 
					&:after{opacity: 1; }
					&:before{border-color: $c_blue; }
				}
			}
		}
	}
}


.top_nav{
	li{
		display: inline-block; vertical-align: middle; margin-left: 10px; 
	}
}