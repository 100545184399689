.tab_menu_ctnr{

	.tab_menu{display: block; @include display-flex;@include flex-flow(row wrap);margin: 0 -5px; 
		li{width: 33.333%; padding: 5px; @include box-sizing(border-box);@include display-flex;@include flex-flow(row nowrap);
			a{
				display: block; padding: 20px 15px; font-weight: 600; text-align: center; border: 1px solid $c_gray_f; @include basic_trs;
				width: 100%; @include box-sizing(border-box);@include box-shadow(0 5px 5px rgba(220, 220, 220, 0.15));
				@include display-flex;@include flex-flow(column nowrap);@include justify-content(center);
				&:hover{color: $c_blue; }
				span{display: inline-block; vertical-align: middle; }
				&:before{font-size: 35px; margin-bottom: 20px; line-height: 35px; display: block; }
			}
			&.active{
				a{
					@include background-image(linear-gradient(56deg, $c_blue_h 0%, $c_blue 100%)); color: #fff; 
				}
			}
			&.error{
				a{border-color: $c_red; color: $c_red; }
				&.active{
					a{
						@include background-image(linear-gradient(56deg, $c_red 0%, $c_red 100%)); color: #fff; 
					}
				}
			}
		}
		&.large{
			a{font-size: 20px; line-height: 1.2; }
		}
	}
	&.small{
		.tab_menu{margin: 0 -5px; 
			li{width: 150px; padding: 5px; 
				a{
					padding: 10px; 
				}
			}
		}
	}

	.customselect-container {display: none; }
}