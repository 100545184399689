@import "modules/common";


.wrapper{width:700px;}



@media only screen and (min-width: 1024px) {
  

	.wrapper{width:700px;}


}


@import "partials/medium/all";
