
#top{
	padding-left: 0; 
	.nav_btn{top: 90px; }
	.logo{width: 246px; margin: 0; padding: 15px; 
		img{width: 100%; display: block; }
	}
	.action_btn{display: none;}
	.action{
		top: 0px; left: auto; right: 0; background-color: transparent;padding: 30px 15px; @include box-shadow(none);
		@include transform(none);@include transition(none);

		.links{display: inline-block; vertical-align: middle; border-left: 1px solid $c_gray_e; 
			li{display: inline-block; vertical-align: middle; margin-left: 10px;
				a{padding: 5px; border-bottom: none; 
					&:before{display: none; }
				}
			}
			.inter{
				a{background-color: $c_orange;color: #fff;@include border-radius(4px);font-size: 14px; padding: 5px 10px; @include basic_trs;
					&:hover{background-color: $c_orange_h;}
				}
			}
			.consult{
				a{background-color: $c_blue;color: #fff;@include border-radius(4px);font-size: 14px; padding: 5px 10px; @include basic_trs;
					&:hover{background-color: $c_blue_h;}
				}
			}
		}
		.user_menu_ctnr{
			display: inline-block; vertical-align: middle; position: relative; 
			.lib{
				display: block; font-size: 0; line-height: 0; padding: 0 30px;  height: 30px; 
				border-left: 1px solid $c_gray_e; margin-left: 10px; @include basic_trs;cursor: pointer; position: relative; 
				&:before{font-size: 22px; line-height: 30px; font-weight: 400; position: absolute; left: 10px; width: 20px; text-align: center; height: 30px;  }
				&:after{
					content:$i_down; font-family: $ff_i; position: absolute; top: 0; height: 30px; right: 0px; width: 30px;
					text-align: center; line-height: 30px; font-size: 7px; font-weight: 400; 
				}
				.action_open &,&:hover{
					color: $c_violet; 
				}
			}
			.user_menu{
				position: absolute; top: 100%;margin-top: 30px; right: -15px; background-color: #fff; width: 200px; padding: 0 10px; @include box-shadow(0 20px 20px -20px rgba(0, 0, 0, 0.15),0 -20px 20px -20px rgba(0, 0, 0, 0.15));
				@include transform(translateY(-300%));@include transition(transform .4s $a_swift_out);
				.action_open &{
					@include transform(translateY(0%));
				}
			}
		}
		.logout{color: $c_gray_9;
			a{border-bottom: none; }
		}
		.action_open &{@include transform(translateX(0%));}
	}
	.search{margin-left: 60px;
		button{cursor: pointer; @include basic_trs;
			&:hover{color: $c_blue; }
		}
	}
}


#nav{top: 150px; right: auto; width: 300px; }



@media only screen and (min-width: 1024px) {
	.site_ctnr{
		padding-left: 220px; max-width: 1300px; @include box-shadow(0 0 10px rgba(0, 0, 0, 0.05));
		&:before{display: none !important; }
	} 
	
	#top{background-color: #fff; padding: 0; border-bottom: 1px solid $c_gray_e; 
		.nav_btn{display: none; }
		.logo{display: none;}
		.search{margin: 0; margin-right: 570px; }
		.action{padding: 15px;
			.links{border: none; }
			.user_menu_ctnr{

				.lib{font-size: 14px; line-height: 30px; padding-left: 40px; font-weight: 600; 
					span{display: inline-block;width: 80px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap; }
				}

				.user_menu{margin-top: 15px;@include box-shadow(none);background-color: $c_gray_f;  }
			}
		}
	}

	#nav{
		top: 0; width: 220px; @include transform(none);@include transition(none);@include box-shadow(0 0 10px rgba(0, 0, 0, 0.05));
		.logo{display: block; background-color: $c_gray_f; padding: 15px 0; 
			img{width: 145px; display: block;margin: 0 auto; }
		}
		>ul{top: 120px; bottom: 35px; height: auto; 
			@include box-shadow(none);
			>li{
				&:last-child{border-bottom: 1px solid $c_gray_e; }
				&.active{
					//padding-right: 0; 
					//&:before{display: none; }
					//ul{max-height: none !important; overflow: visible; margin: 0; }
					a{
						&:hover{color: $c_main; }
					}
				}
				a{@include basic_trs;
					&:hover{color: $c_blue; }
				}
			}
		}
	}


}